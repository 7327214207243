import KikoffCashAdvanceContainer from "@component/landing/cash_advance/container";

interface HomepageProps {
  cblModule?: boolean;
}

// NOTE: Do not add "getLayout" do this component. We're duplicating the
//   homepage on a different landing page for an experiment with no menus/nav.
//   See https://kikoff.atlassian.net/browse/AMF-834.
function Homepage({ cblModule = false }: HomepageProps) {
  return <KikoffCashAdvanceContainer flow="waitlist" />;
}

export default Homepage;
